export default class MapMarkerAdapter {
  constructor({
    id,
    lat,
    lng,
    map,
    animationDelay,
    icon,
    popupContent,
    usePopup = true,
  }) {
    this._id = id;
    this._lat = lat;
    this._lng = lng;
    this._map = map;
    this._animationDelay = animationDelay;

    this._popupContent = popupContent;

    this._icon = icon;

    // this._popupTemplate = document.getElementById("marker-popup-template");

    // this._usePopup = usePopup;

    // this._icon = {
    //   // url: "/wp-content/themes/qualsight/icons/map-marker.svg",
    //   // scaledSize: new google.maps.Size(23, 34),
    //   // title: 'ld'

    //   fillColor: "blue",
    //   fillOpacity: 0.6,
    //   strokeWeight: 0,
    //   rotation: 0,
    //   scale: 2,
    //   anchor: new google.maps.Point(0, 20),
    // };

    this._marker = this._initMarker();

    this._placeMarker();
  }

  // _generatePopupContent() {
  //   const { title, address, id, url } = this._data;
  //   // clone template
  //   const popupEl =
  //     this._popupTemplate.content.firstElementChild.cloneNode(true);
  //   // set title
  //   const link = popupEl.querySelector(".location-marker-popup__link");
  //   link.textContent = title;
  //   link.href = url;
  //   // set address
  //   popupEl.querySelector(".location-marker-popup__address").textContent =
  //     address;
  //   // set
  //   popupEl.querySelector(".location-marker-popup__address").textContent =
  //     address;
  //   // set appt link
  //   popupEl.querySelector(".location-marker-popup__button--req").href =
  //     "/free-lasik-exam?location=" + title;
  //   // set info link
  //   popupEl.querySelector(".location-marker-popup__button--info").href = url;

  //   return popupEl.outerHTML;
  // }

  _initMarker() {
    const marker = new google.maps.Marker({
      position: {
        lat: this._lat,
        lng: this._lng,
      },
      icon: this._icon,
      // animation: google.maps.Animation.DROP,
      // map: this._map,
    });

    if (this._usePopup) {
      // set popup
      this._infoWindow = new google.maps.InfoWindow({
        content: this._popupContent,
        // ariaLabel: "Uluru",
      });
    }

    // set id
    marker.set("id", this._id);

    return marker;
  }

  _placeMarker() {
    if (this._animationDelay) {
      return setTimeout(() => {
        this._marker.setMap(this._map);
      }, this._animationDelay);
    }

    this._marker.setMap(this._map);
  }

  click(fn) {
    this._marker.addListener("click", (e) => fn(e, this._marker));
  }

  closePop() {
    this._infoWindow.close();
  }

  id() {
    return this._id;
  }

  getInstance() {
    return this._marker;
  }

  show() {
    this._placeMarker();
  }

  remove() {
    this._marker.setMap(null);
  }

  getCoordinates() {
    return this._marker.getPosition();
    if (this._usePopup) {
      this._marker.addListener("click", () => {
        this._infoWindow.open({
          anchor: this._marker,
          map: this._map,
        });
        fn();
      });
    }
  }
}
