export default class Location {
  constructor({ title, address, id, url, coordinates, map }) {
    this._title = title;
    this._address = address;
    this._id = id;
    this._url = url;
    this._coordinates = coordinates;
    this._map = map;

    this._popupTemplate = document.getElementById("marker-popup-template");
    this._element =
      this._popupTemplate.content.firstElementChild.cloneNode(true);

    this._initView();
  }

  set marker(marker) {
    this._marker = marker;
    this._marker.setMap(this._map);
  }

  get marker() {
    return this._marker;
  }

  set infoWindow(infoWindow) {
    this._infoWindow = infoWindow;
  }

  get infoWindow() {
    return this._infoWindow;
  }

  get map() {
    return this._map;
  }

  get coordinates() {
    return this._coordinates;
  }

  get distance() {
    return this._distance;
  }

  set distance(distance) {
    this._distance = distance;
    const distanceDisplayEl = this._element.querySelector(
      ".location-marker-popup__distance"
    );
    distanceDisplayEl.textContent = `${distance.toFixed(2)} miles`;
  }

  deactivate() {
    // remove marker
    this._marker.setMap(null);
    this._infoWindow.close();
  }

  activate() {
    this._marker.setMap(this._map);
  }

  onListItemClick(fn) {
    this._element.addEventListener("click", (e) => fn(e, this));
  }

  closeInfoWindow() {
    this._infoWindow.close();
  }

  openInfoWindow() {
    this._infoWindow.open({
      anchor: this._marker,
      map: this._map,
    });
  }

  unFocusListItem() {
    this._element.classList.remove("location-finder__location-item--focus");
  }

  focusListItem() {
    this._element.classList.add("location-finder__location-item--focus");
    this._element.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
      inline: "start",
    });
  }

  _initView() {
    // set title
    const link = this._element.querySelector(".location-marker-popup__link");
    link.textContent = this._title;
    link.href = this._url;
    // set address
    this._element.querySelector(".location-marker-popup__address").textContent =
      this._address;
    // set
    this._element.querySelector(".location-marker-popup__address").textContent =
      this._address;
    // set appt link
    this._element.querySelector(".location-marker-popup__button--req").href =
      "/free-lasik-exam?location=" + this._title;
    // set info link
    this._element.querySelector(".location-marker-popup__button--info").href =
      this._url;

    const li = document.createElement("li");
    li.className = "location-finder__location-item";
    li.append(this._element);

    this._element = li;
  }

  getView() {
    return this._element;
  }

  getPopupView() {
    return this._element.firstElementChild.outerHTML;
  }
}
