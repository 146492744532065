const { themeURL } = window.WP_VARS;
// LINK: https://github.com/googlemaps/js-markerclusterer/blob/822ee6bccacd0b3a70ba55680fdaa3192ae7a9ab/src/renderer.ts#L52
export default class CustomClusterRenderer {
  render({ count, position }) {
    // create svg url with fill color
    //   const svg = window.btoa(`
    // <svg fill="#00A8F7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    //   <circle cx="120" cy="120" opacity="1" r="110" />
    // </svg>`);

    return new google.maps.Marker({
      position,
      icon: {
        // url: `data:image/svg+xml;base64,${svg}`,
        url: themeURL + "/icons/cluster-marker.svg",
        scaledSize: new google.maps.Size(40, 40),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,0.9)",
        fontSize: "14px",
        fontWeight: "900",
      },
      title: `Cluster of ${count} markers`,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  }
}
