class ZeroResults extends Error {
  constructor(message) {
    super(message);
    this.name = "ZeroResults";
  }
}

export default class GoogleMapsAPI {
  constructor(apiKey) {
    this._apiKey = apiKey;

    this._apiBase = `https://maps.googleapis.com/maps/api/geocode/json?key=${this._apiKey}`;

    this._api = window.google.maps;

    if (!this._api) {
      console.error("Google api not found");
    }
  }

  _checkResponseStatus(res) {
    return res.ok ? res.json() : Promise.reject(`Error: ${res.status}`);
  }

  geocodeAddress(address) {
    return new Promise((resolve, reject) => {
      const geocoder = new this._api.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status !== "OK")
          return reject("Unable to geocode for address " + address);
        resolve(results);
      });
    });
  }

  distanceBetween(latLng1, latLng2) {
    return this._getMiles(
      this._api.geometry.spherical.computeDistanceBetween(latLng1, latLng2)
    );
  }

  _getMiles(meters) {
    return meters * 0.000621371192;
  }

  /**
   * Get only lat and long
   *
   * @param String address
   * @returns Object {lat: Number, lng: Number}
   */
  getCoordinates(address) {
    return this.geocodeAddress(address)
      .then((res) => {
        if (!res.results[0]) {
          return null;
        }
        return res.results[0].geometry.location;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  reverseGeocode({ lat, lng }) {
    return fetch(
      `${this._apiBase}&latlng=${lat},${lng}&result_type=street_address|locality`
    )
      .then(this._checkResponseStatus)
      .then((res) => {
        if (res.status === "ZERO_RESULTS") {
          throw new ZeroResults(`No results found for ${lat}, ${lng}`);
        }
        return res;
      });
  }
}
