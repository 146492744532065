import MapAdapter from "./modules/MapAdapter";
import MapMarkerAdapter from "./modules/MapmarkerAdapter";
import GoogleMapsAPI from "./modules/GoogleMapsAPI";
import Location from "./modules/Location";
import MarkerClustererAdapter from "./modules/MarkerClustererAdapter";
import CustomClusterRenderer from "./modules/CustomClusterRenderer";

const { apiKey, locations } = window.MAP;
const { themeURL } = window.WP_VARS;

const mapContainer = document.querySelector(".location-finder__map-container");
const mapElement = document.getElementById("gmap-container");
const searchForm = document.querySelector(".location-finder__form");
const distanceInput = document.querySelector("#max-distace-away-input");
const currentLocationBtn = document.querySelector("#current-location-btn");
const locationListEl = document.querySelector(
  ".location-finder__location-list"
);
const loadMoreButtonTemplate =
  document.querySelector("#load-more-button").content.firstElementChild;
const searchLocationsButton = document.querySelector(
  "#search-locations-button"
);

// center of the US mainland
const center = {
  lat: 39.8097343,
  lng: -98.555619,
};

/* -------------------------------------------------------------------------- */
/*                             Safari Focus Fixes                             */
/* -------------------------------------------------------------------------- */
distanceInput.addEventListener("blur", () => {
  // focus next input
  searchLocationsButton.focus();
});

searchLocationsButton.addEventListener("blur", () => {
  // focus next input
  currentLocationBtn.focus();
});

/* -------------------------------------------------------------------------- */
/*                                     Map                                    */
/* -------------------------------------------------------------------------- */

const markers = [];

let visibleLocationsCursor = 0;

let focusedLocation = null;

const setFocusedLocation = (mapLocation) => {
  if (focusedLocation) {
    focusedLocation.closeInfoWindow();
    focusedLocation.unFocusListItem();
  }
  focusedLocation = mapLocation;
  focusedLocation.focusListItem();
  focusedLocation.openInfoWindow();
};

const startLoading = () => {
  mapContainer.classList.add("location-finder__map-container--loading");
};

const stopLoading = () => {
  mapContainer.classList.remove("location-finder__map-container--loading");
};

// const svg = window.btoa(
//   '<svg width="28" height="37" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero" fill="none"><path d="M14 37s14-15.031 14-23.125C28 6.215 21.73 0 14 0S0 6.215 0 13.875C0 21.969 14 37 14 37Z" fill="#196ECF"/><path d="M14.5 18a4.504 4.504 0 0 1-4.5-4.5c0-2.482 2.018-4.5 4.5-4.5s4.5 2.018 4.5 4.5-2.018 4.5-4.5 4.5Z" fill="#C0E9FF"/></g></svg>'
// );

const createMarker = ({ lat, lng }) => {
  const m = new MapMarkerAdapter({
    lat: parseFloat(lat),
    lng: parseFloat(lng),
    // id: location.id,
    icon: {
      // url: `data:image/svg+xml;base64,${svg}`,
      url: themeURL + "/icons/marker.svg",
      scaledSize: new google.maps.Size(24, 33),
    },
  });

  return m;
};

// const clearMarkers = () => {
//   markers.forEach((m) => m.remove());
// };

// const addMarkersToMap = (markers, map) => {
//   markers.forEach((m) => m.getInstance().setMap(map));
// };

const renderLocations = (locations) => {
  const locationElements = locations.map((l) => {
    const locationElement = l.getView();
    locationElement.classList.add("location-finder__location-item--visible");
    return locationElement;
  });
  locationListEl.prepend(...locationElements);
  locationListEl.scrollTo({ top: 0, behavior: "smooth" });
};

const generateLoadMoreButton = () => {
  const button = document.querySelector(".location-finder__load-more-item");
  if (button) {
    // button.remove();
    button.style.display = "none";
  }

  const itemEl = loadMoreButtonTemplate.cloneNode(true);
  const buttonEl = itemEl.querySelector(".button");
  buttonEl.addEventListener("click", loadMoreLocations);
  locationListEl.append(itemEl);
};

const loadMoreLocations = () => {
  for (let i = 0; i < 10; i++) {
    const nextItem = locationListEl.children.item(visibleLocationsCursor);
    if (!nextItem) break;
    nextItem.classList.add("location-finder__location-item--visible");
    visibleLocationsCursor++;
  }

  if (!locationListEl.children.item(visibleLocationsCursor)) {
    // keep load button
    // generateLoadMoreButton();
    loadMoreItem.remove();
  } else {
    generateLoadMoreButton();
  }
};

const renderLocation = (location) => {
  locationListEl.append(location.getView());
};

const emptyLocationList = () => {
  locationListEl.innerHTML = "";
};

const sortLocationsByDistance = (locations) => {
  return locations.sort((locationA, locationB) =>
    parseFloat(locationA.distance) > parseFloat(locationB.distance) ? 1 : -1
  );
};

const findClosestMarkers = (coordinates, maxDistance, cm, mapApi) => {
  // clear map of all markers and clusters
  // clearMarkers();
  emptyLocationList();
  cm.clearMarkers();

  const activeMarkers = [];
  const activeLocations = [];

  markers.forEach((m, i) => {
    // calculate distance
    const latLng = new google.maps.LatLng(m.coordinates.lat, m.coordinates.lng);
    const distance = mapApi.distanceBetween(latLng, coordinates);
    if (distance <= maxDistance) {
      // activeMarkers.push(m);
      // m.show();
      m.distance = distance;
      m.activate();
      activeMarkers.push(m.marker);
      activeLocations.push(m);
      // renderLocation(m);
    } else {
      m.deactivate();
    }
  });

  // sort locations by distance
  const sortedLocations = sortLocationsByDistance(activeLocations);
  renderLocations(sortedLocations);

  // rerender clusterer
  // cm.addMarkers(markers.map((m) => m.getInstance()).filter((m) => m.getMap()));
  cm.addMarkers(activeMarkers);

  return activeLocations;
};

const getUserLocation = async () =>
  new Promise((resolve, reject) => {
    const storedLocaton = localStorage.getItem("location");
    // check for cached location
    if (storedLocaton) return resolve(JSON.parse(storedLocaton));
    // find location
    if (!navigator.geolocation)
      reject(new Error("Geolocation is not supported by this browser."));
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        const { latitude, longitude } = coords;
        // store locaton in local storage
        localStorage.setItem(
          "location",
          JSON.stringify({ latitude, longitude })
        );
        return resolve(coords);
      },
      (e) => reject(e)
    );
  });

window.initMap = () => {
  const mapApi = new GoogleMapsAPI(apiKey);

  const map = new MapAdapter({ container: mapElement, center, zoom: 5 });

  const fitBounds = (activeLocations) => {
    var bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < activeLocations.length; i++) {
      bounds.extend(activeLocations[i].marker.getPosition());
    }
    //center the map to the geometric center of all markers
    map.getInstance().setCenter(bounds.getCenter());

    map.getInstance().fitBounds(bounds);
  };

  map.init();

  // process locations
  locations.forEach((data, index) => {
    // console.log(parseFloat(location.coordinates.lat));
    const locationItem = new Location({
      ...data,
      map: map.getInstance(),
    });

    locationItem.infoWindow = new google.maps.InfoWindow({
      content: locationItem.getPopupView(),
    });

    const marker = createMarker(data.coordinates);

    locationItem.marker = marker.getInstance();

    marker.click(() => {
      // open info window
      setFocusedLocation(locationItem);
    });

    locationItem.onListItemClick(() => {
      setFocusedLocation(locationItem);

      map.getInstance().setZoom(13);
      map.getInstance().setCenter(marker.getInstance().getPosition());
    });

    markers.push(locationItem);
  });

  renderLocations(markers);
  setTimeout(() => loadMoreLocations(), 0);

  // addMarkersToMap(markers, map.getInstance());

  // markerClusterer.add(markers.map((m) => m.getInstance()));
  // cm.addMarkers(markers.map((m) => m.getInstance()));

  const updateMapWithUserLocation = async () => {
    // reset loading if no location found after 4 seconds
    setTimeout(() => {
      if (!localStorage.getItem("location")) {
        stopLoading();
        console.log("not loading");
      }
    }, 4000);

    startLoading();
    const { latitude, longitude } = await getUserLocation();

    const latLng = new google.maps.LatLng(latitude, longitude);

    fitBounds(
      findClosestMarkers(latLng, parseInt(distanceInput.value), cm, mapApi)
    );

    stopLoading();
  };

  const cm = new markerClusterer.MarkerClusterer({
    markers: markers.map((m) => m.marker),
    // markers: [],
    map: map.getInstance(),
    renderer: new CustomClusterRenderer(),
  });

  currentLocationBtn.addEventListener("click", updateMapWithUserLocation);

  searchForm.addEventListener("submit", async (e) => {
    e.preventDefault();

    const zip = e.target.elements.zip.value;
    const maxDistance = parseInt(e.target.elements.distance.value);

    const results = await mapApi.geocodeAddress(zip + ", USA");

    const coordinates = results[0].geometry.location;

    fitBounds(findClosestMarkers(coordinates, maxDistance, cm, mapApi));
  });

  updateMapWithUserLocation();
};
